import { Seo } from 'components';
import Header from 'components/Header';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { usePageView, useQuizData } from 'utils/hooks';
import Loader from '../calculating/components/Loader';
import Button from '../results/components/StartNowButton';
import { tablet } from 'styles/breakpoints';
import { AppState } from 'state/types';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'apis/history';
import { config } from 'config';
import axios from 'axios';
import { handleError } from 'utils/error';
import { getCountries } from 'countries-and-states';
import iso3166 from 'iso-3166-2';
import { updateLead, updateShippingAddress } from 'state/user/effects';
import { normalizeStates } from 'utils/localization';
import ShippingForm from './components/ShippingForm';
import PaymentSteps from '../payments/components/PaymentSteps';
import projectConfig from 'state/config/reducer';
import SuccessSupportCase from './components/SuccessSupportCase';

interface FormData {
  country: string;
  state: string | { label: string; value: string };
  firstName: string;
  lastName: string;
  city: string;
  street: string;
  apartament: string;
  phone: string;
  postalCode: string;
}

const Shipping = () => {
  const { selected_plans, user, code, shipping_address, upsell_products } =
    useSelector((state: AppState) => state.user);

  const [countries, setCountries] = useState<any[]>([]);
  const [shippingErrors, setShippingErrors] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [isShippingAdressComplete, setIsShippingAdressComplete] =
    useState<boolean>(false);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  const data = useQuizData('shipping');
  const checkoutData = useQuizData('checkout');
  const pageOrder = checkoutData?.pageOrder || [];
  const { quiz_answers, geolocation } = useSelector(
    (state: AppState) => state.user,
  );
  const dogBreedList = useSelector((state: AppState) => state.dogBreed.list);

  const { goToRegister, goToSuccess } = useRouter();
  const caseParam = new URLSearchParams(location.search).get('case');
  const isFromSupportCase = caseParam === 'support';

  const dispatch = useDispatch();
  usePageView({
    country: geolocation?.iso_country?.toLowerCase() || 'no-country-fallback',
    state: normalizeStates(
      geolocation?.iso_country || '',
      geolocation?.iso_state || '',
    ),
    city: encodeURIComponent(
      geolocation?.city?.toLowerCase().replace(/[^a-z0-9]/g, '') || '',
    ),
    email: user?.email.trim() || 'no-email-fallback',
    client_code: code,
  });

  useEffect(() => {
    fetchCountries();
  }, []);

  const fetchCountries = async () => {
    try {
      const countriesData = await getCountries('en');
      const mapedCountries = countriesData.map(item => ({
        value: item.countryCode,
        label: item.countryName,
      }));
      setCountries(mapedCountries);
    } catch (error) {
      console.error('Error fetching countries:', error);
    }
  };

  const buildValueKey = (item: string) =>
    item.toLowerCase().replace(/\s+/g, '_');

  const colorWord = (item: string) => {
    let questionMarkIndex = item.indexOf('?');
    let lastUnderscoreIndex = item.lastIndexOf('_', questionMarkIndex);
    let color = item.substring(lastUnderscoreIndex + 1, questionMarkIndex);
    return color;
  };

  const taxedCountries = data?.taxCodes ?? {};

  const buildShippingAddress = (data: any) => {
    const organization =
      data.organization && taxedCountries[data.country.trim()]
        ? `${taxedCountries[data.country.trim()]}${data.organization.trim()}`
        : undefined;

    return {
      userEmail: (user?.email || '').trim(),
      city: data.town.trim(),
      country_code: data.country.trim(),
      name: `${data.firstName.trim()} ${data.lastName.trim()}`,
      phoneNumber: data.phoneNumber.trim(),
      postcode: data.zipCode.trim(),
      state_code: iso3166.subdivision(data.state.trim())?.regionCode ?? '',
      street1: data.street.trim(),
      street2: data.apartment.trim(),
      organization: organization,
    };
  };

  const getFirebaseShipBookPayload = (shippingAddress: any) => {
    const bookString = quiz_answers?.coverUrl;
    const index = bookString.indexOf('#');
    const finalString = bookString.substring(index);
    const result = finalString.replace(/\.png$/, '');

    return {
      hardCover: quiz_answers?.hardCover || false,
      bookColor: colorWord(result),
      userName:
        `${quiz_answers?.userName?.trim()} & ${quiz_answers?.userDogName?.trim()}`.trim(),
      contentReady:
        dogBreedList.find(
          item => item.value === buildValueKey(quiz_answers?.dogs_breed).trim(),
        )?.contentReady || false,
      breed: buildValueKey(quiz_answers?.dogs_breed).trim(),
      hairType:
        quiz_answers?.dog_coat_type === 'none'
          ? null
          : quiz_answers?.dog_coat_type,
      clientCode: code,
      email: user?.email || ''.trim(),
      shippingAddress,
    };
  };

  const handleShippingError = async (error: any, shippingAddress: any) => {
    setLoading(false);
    setShippingErrors(prev => [...prev, 'Something has gone wrong!']);

    if (axios.isAxiosError(error)) {
      const responseData = error.response?.data;
      if (responseData?.errorMessage) {
        setErrorMessages(prev => [...prev, responseData.errorMessage]);
      }
    }

    dispatch(
      updateLead(code, {
        quiz_answers: {
          ...quiz_answers,
          shipping_address: shippingAddress,
        },
      }),
    );

    try {
      await axios.post(config.FIREBASE_SHIPPING_ADDRESS, {
        email: user?.email,
        shippingAddress: shippingAddress,
      });
    } catch (err) {
      handleError(err);
    }
  };

  const determineNavigationTarget = (
    pageOrder: any,
    selectedPlans: any[],
    upsellProducts: any[],
  ): 'register' | 'success' => {
    for (const obj of pageOrder) {
      for (const [key, items] of Object.entries(obj)) {
        const hasMatch = (items as string[]).some(
          item =>
            selectedPlans?.some((plan: any) => plan.key === item) ||
            upsellProducts?.some((prod: any) => prod.key === item),
        );
        if (hasMatch && key === 'register') {
          return 'register';
        }
      }
    }
    return 'success';
  };

  const navigate = (target: 'register' | 'success') => {
    if (target === 'register') {
      goToRegister();
    } else {
      goToSuccess();
    }
  };

  const handleSubmit = async (data: any) => {
    setLoading(true);
    setErrorMessages([]);
    setShippingErrors([]);

    const shippingAddress = buildShippingAddress(data);
    dispatch(updateShippingAddress(shippingAddress));

    let encounteredError = false;
    try {
      await axios.post(config.DUPLICATE_CHECK, { clientCode: code });
      axios.post(
        config.FIREBASE_SHIP_BOOK,
        getFirebaseShipBookPayload(shippingAddress),
      );
      // klaviyoShipLink(user?.email || '', quiz_answers.contentReady || false);
      dispatch(
        updateLead(code, {
          quiz_answers: {
            ...quiz_answers,
            shipping_address: shippingAddress,
          },
        }),
      );
    } catch (e) {
      encounteredError = true;
      await handleShippingError(e, shippingAddress);
    }

    if (errorMessages.length > 0 || encounteredError) {
      setLoading(false);
      console.error(errorMessages);
      return;
    }

    if (isFromSupportCase) {
      setLoading(false);
      setIsShippingAdressComplete(true);
      return;
    }

    const target = determineNavigationTarget(
      pageOrder,
      selected_plans,
      upsell_products,
    );

    setLoading(false);
    navigate(target);
  };

  if (!data) {
    return <Loader />;
  }

  return (
    <>
      <Seo title="Dog Training Book | Raising Dog" />
      <Header logoVariant="center" color={'light0'} hasBorder={true} />
      {isShippingAdressComplete &&
      isFromSupportCase &&
      errorMessages.length === 0 ? (
        <SuccessSupportCase />
      ) : (
        <>
          <PaymentStepsContainer>
            <PaymentStepsStyled
              {...data?.paymentSteps}
              secondStepDot="tick"
              optionalStepDot="checked"
            />
          </PaymentStepsContainer>
          <Container>
            <Card>
              <TitleContainer>
                <Title>{data.title}</Title>
                <SubTitle dangerouslySetInnerHTML={{ __html: data.subTitle }} />
              </TitleContainer>
              <ShippingForm
                countries={countries}
                submitHandler={handleSubmit}
                formValues={shipping_address ?? undefined}
                loading={loading}
                shippingErrors={errorMessages}
              />
            </Card>
          </Container>
        </>
      )}
    </>
  );
};

export default Shipping;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  max-width: 28.75rem;
  width: 100%;
`;

const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1.5rem;
  margin-bottom: 2rem;
`;

const StyledButton = styled(Button)`
  max-width: 560px;
  margin-top: 1.5rem;
  margin-bottom: 4rem;
  width: 100%;
  background: #804da1;
  border-radius: 100px;
`;

const Title = styled.p`
  color: #000;
  text-align: center;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.8125rem;
  @media ${tablet} {
    font-size: 1.5rem;
    line-height: 1.8125rem;
  }
`;

const SubTitle = styled.p`
  color: #1c1c28;
  font-size: 0.875rem;
  margin-bottom: 1rem;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem 1rem;
  background: #f6f4ef;

  @media ${tablet} {
    padding: 0;
    background: #fff;
  }
`;

const Card = styled.div`
  border-radius: 1.25rem;
  background: #fff;
  max-width: 28.75rem;
  width: 100%;
  padding: 2.25rem;

  @media ${tablet} {
    box-shadow: unset;
    padding: 1.5rem 1rem 4rem;
  }
`;

const PaymentStepsContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0.75rem 0;

  @media ${tablet} {
    padding: 0.5rem 0;
  }
`;

const PaymentStepsStyled = styled(PaymentSteps)`
  width: 100%;
`;
