import React, { FC, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import styled from 'styled-components';
import { useQuizData } from 'utils/hooks';
import { getAddressDetails } from 'utils/google-places';
import iso3166 from 'iso-3166-2';
import { AddressAutocomplete } from './AddressAutocomplete';
import Button from 'components/PrimaryButton';
import { tablet } from 'styles/breakpoints';
import Select, {
  components,
  DropdownIndicatorProps,
  SingleValue,
  StylesConfig,
} from 'react-select';
import CustomSelect from 'assets/icons/select-indicator.svg';
import CircleCheck from 'assets/icons/check-circle.svg';

interface Country {
  value: string;
  label: string;
}

interface State {
  value: string;
  label: string;
}

interface IFormValidationField {
  required?: string;
  long?: string;
  valid?: string;
  combinedNames?: string;
  short?: string;
}

interface IFormValidationSchema {
  common?: IFormValidationField;
  firstName?: IFormValidationField;
  lastName?: IFormValidationField;
  phoneNumber?: IFormValidationField;
  street?: IFormValidationField;
  apartment?: IFormValidationField;
  town?: IFormValidationField;
  state?: IFormValidationField;
  country?: IFormValidationField;
  zipCode?: IFormValidationField;
  organization?: IFormValidationField;
}

interface FormValues {
  name?: string;
  phoneNumber?: string;
  street1?: string;
  street2?: string;
  city?: string;
  state_code?: string;
  country_code?: string;
  postcode?: string;
  organization?: string;
}

interface ShippingFormProps {
  countries?: Country[];
  submitHandler: (event: React.FormEvent<HTMLFormElement>) => Promise<void>;
  formValues?: FormValues;
  loading?: boolean;
  shippingErrors?: string[];
}

type FieldName =
  | 'firstName'
  | 'lastName'
  | 'phoneNumber'
  | 'street'
  | 'apartment'
  | 'town'
  | 'state'
  | 'country'
  | 'zipCode'
  | 'organization';

const DropdownIndicator = (props: DropdownIndicatorProps) => (
  <components.DropdownIndicator {...props}>
    <StyledCustomSelect disabled={props.selectProps.isDisabled} />
  </components.DropdownIndicator>
);

const ShippingForm: FC<ShippingFormProps> = ({
  countries,
  submitHandler,
  formValues,
  loading,
  shippingErrors,
}) => {
  const data = useQuizData('shipping');
  const [states, setStates] = useState<State[]>([]);
  const [showFreeShipping, setShowFreeShipping] = useState<boolean>(false);

  const validations = data?.validations as IFormValidationSchema;

  const taxedCountries = data?.taxCodes ? Object.keys(data.taxCodes) : [];

  const schema = Yup.object().shape({
    apartment: Yup.string().max(30, validations?.common?.long),
    country: Yup.string()
      .required(validations?.country?.required)
      .max(30, validations?.common?.long),
    firstName: Yup.string()
      .required(validations?.firstName?.required)
      .max(30, validations?.common?.long),
    lastName: Yup.string()
      .required(validations?.lastName?.required)
      .max(30, validations?.common?.long)
      .test(
        'combined-names',
        validations?.common?.combinedNames ?? '',
        function (value) {
          const firstName = this.parent.firstName;
          if (!value || !firstName) return true;
          return value.length + firstName.length <= 35;
        },
      ),
    organization: Yup.string().when('country', {
      is: (country: string) => taxedCountries.includes(country),
      then: schema =>
        schema
          .required(validations?.organization?.required)
          .matches(
            /^[a-zA-Z0-9\s.\u00C0-\u1EFF\u1D00-\u1D7F-]+$/,
            validations?.organization?.valid,
          )
          .min(3, validations?.organization?.short),
      otherwise: schema => schema.notRequired(),
    }),
    phoneNumber: Yup.string()
      .required(validations?.phoneNumber?.required)
      .matches(/^\+?[\d\s\-.\/()]{8,20}$/, validations?.phoneNumber?.valid),
    state: Yup.string()
      .required(validations?.state?.required)
      .max(30, validations?.common?.long),
    street: Yup.string()
      .required(validations?.street?.required)
      .max(30, validations?.common?.long),
    town: Yup.string()
      .required(validations?.town?.required)
      .max(30, validations?.common?.long),
    zipCode: Yup.string()
      .required(validations?.zipCode?.required)
      .max(30, validations?.common?.long),
  });

  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    watch,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      firstName: formValues?.name!.split(' ')[0] ?? '',
      lastName: formValues?.name!.split(' ')[1] ?? '',
      phoneNumber: formValues?.phoneNumber ?? '',
      street: formValues?.street1 ?? '',
      apartment: formValues?.street2 ?? '',
      town: formValues?.city ?? '',
      state:
        formValues?.country_code && formValues?.state_code
          ? `${formValues?.country_code}-${formValues?.state_code}`
          : '',
      country: formValues?.country_code ?? '',
      zipCode: formValues?.postcode ?? '',
      organization: formValues?.organization ?? '',
    },
  });

  const watchCountry = watch('country');

  useEffect(() => {
    if (watchCountry) {
      const stateOptions = renderStateOptions(watchCountry);
      setStates(stateOptions);

      const currentState = getValues('state');

      const isValidState = stateOptions.some(
        state => state.value === currentState,
      );

      if (!isValidState) {
        setValue('state', '');
      }

      setValue('organization', '', {
        shouldValidate: false,
        shouldDirty: false,
        shouldTouch: false,
      });

      if (errors.organization) {
        clearErrors('organization');
      }

      setShowFreeShipping(getValues('country') !== '');
    }
  }, [watchCountry, setValue, getValues]);

  const renderStateOptions = (countryCode: string): State[] => {
    if (countryCode && iso3166.data[countryCode]) {
      return Object.entries(iso3166.data[countryCode].sub).map(
        ([value, { name: label }]) => ({
          value,
          label,
        }),
      );
    }
    return [];
  };

  const onAddressSelect = async (address: string) => {
    const details = await getAddressDetails(address);

    if (details) {
      const countryObj = countries?.find(
        country => country.value === details.country,
      );

      if (countryObj) {
        setValue('country', countryObj.value, { shouldValidate: true });
      }

      const stateOptions = renderStateOptions(details.country);

      const stateObj = stateOptions.find(
        state =>
          state.label.toLowerCase() === details.state?.toLowerCase() ||
          state.value.toLowerCase() === details.state?.toLowerCase(),
      );

      if (stateObj) {
        setValue('state', stateObj.value, { shouldValidate: true });
      }

      setValue('street', details.address ?? '', { shouldValidate: true });

      if (details.city) {
        setValue('town', details.city, { shouldValidate: true });
      }

      if (details.postalCode) {
        setValue('zipCode', details.postalCode, { shouldValidate: true });
      }
    }
  };

  useEffect(() => {
    if (states.length > 0 && formValues?.state_code) {
      const stateObj = states.find(
        state => state.value === formValues.state_code,
      );
      if (stateObj) {
        setValue('state', stateObj.value);
      }
    }
  }, [states, formValues?.state_code, setValue]);

  const onSubmit = async (formData: any) => {
    await submitHandler(formData);
  };

  const style: StylesConfig<any> = {
    control: (styles, state) => ({
      ...styles,
      backgroundColor: 'white',
      borderRadius: '0.625rem',
      height: '3rem',
      background: '#FFF',
      fontFamily: 'Manrope',
      fontSize: '1rem',
      fontWeight: '400',
      border: state.selectProps.hasError
        ? '1px solid#962828'
        : state.isFocused
        ? '1px solid #4D4D4D'
        : '1px solid#C7C9D9',
      boxShadow: 'none',
      ':hover': {
        border: state.selectProps.hasError
          ? '1px solid#962828'
          : state.isFocused
          ? '1px solid #4D4D4D'
          : '1px solid#C7C9D9',
      },
      cursor: 'text',
    }),
    valueContainer: styles => ({
      ...styles,
      marginLeft: '1rem',
      padding: 'unset',
    }),
    menu: styles => ({ ...styles, margin: 'unset' }),
    placeholder: styles => ({ ...styles, color: '#8f90a6' }),
    singleValue: (styles, { data }) => ({ ...styles }),
  };

  const selectStyle: StylesConfig<any> = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      backgroundColor: 'white',
      borderRadius: '0.625rem',
      height: '3rem',
      background: '#FFF',
      fontFamily: 'Manrope',
      fontSize: '1rem',
      fontWeight: '400',
      border: state.selectProps.hasError
        ? '1px solid#962828'
        : state.isFocused
        ? '1px solid #4D4D4D'
        : state.isDisabled
        ? '1px solid #C7C9D9'
        : '1px solid#C7C9D9',
      boxShadow: 'none',
      ':hover': {
        border: state.selectProps.hasError
          ? '1px solid#962828'
          : state.isFocused
          ? '1px solid #4D4D4D'
          : '1px solid#C7C9D9',
      },
      cursor: 'pointer',
    }),
    valueContainer: styles => ({
      ...styles,
      marginLeft: '1rem',
      padding: 'unset',
    }),
    menu: styles => ({ ...styles, margin: 'unset' }),
    placeholder: (styles, state) => ({
      ...styles,
      color: state.isDisabled ? '#C7C9D9' : '#8C8C8C',
    }),
    singleValue: (styles, { data }) => ({ ...styles }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    dropdownIndicator: () => ({
      marginRight: '1rem',
      display: 'flex',
      alignItems: 'center',
    }),
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    fieldName: FieldName,
  ) => {
    const value = e.target.value.replace(
      /[^a-zA-Z0-9\s.\u00C0-\u024F\u1E00-\u1EFF\u0180-\u024F\u1D00-\u1D7F\u0400-\u04FF-]/g,
      '',
    );
    setValue(fieldName, value, { shouldValidate: true });
  };

  const handleOrganizationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(
      /[^a-zA-Z0-9\s.,\u00C0-\u024F\u1E00-\u1EFF\u0180-\u024F\u1D00-\u1D7F\u0400-\u04FF-]/g,
      '',
    );
    setValue('organization', value, { shouldValidate: !!errors.organization });
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <InputContainer>
          <SectionTitle>{data?.customerDetailsSectionLabel}</SectionTitle>
        </InputContainer>
        <InputContainer>
          <Label>{data?.firstNameLabel}</Label>
          <Input
            placeholder=""
            type="text"
            {...register('firstName')}
            hasError={!!errors.firstName?.message}
            onChange={e => handleInputChange(e, 'firstName')}
          />
          {errors.firstName?.message && (
            <ErrorMessage>{errors.firstName?.message}</ErrorMessage>
          )}
        </InputContainer>
        <InputContainer>
          <Label>{data?.lastNameLabel}</Label>
          <Input
            placeholder=""
            type="text"
            {...register('lastName')}
            hasError={!!errors.lastName?.message}
            onChange={e => handleInputChange(e, 'lastName')}
          />
          {errors.lastName?.message && (
            <ErrorMessage>{errors.lastName?.message}</ErrorMessage>
          )}
        </InputContainer>
        <InputContainer>
          <Label>{data?.phoneLabel}</Label>
          <Input
            placeholder=""
            type="text"
            {...register('phoneNumber')}
            hasError={!!errors.phoneNumber?.message}
          />
          {errors.phoneNumber?.message && (
            <ErrorMessage>{errors.phoneNumber?.message}</ErrorMessage>
          )}
        </InputContainer>
        <InputContainer>
          <SectionTitle>{data?.shippingAddressSectionLabel}</SectionTitle>
        </InputContainer>
        <InputContainer>
          <Label>{data?.streetLabel}</Label>
          <Controller
            name="street"
            control={control}
            render={({ field }) => (
              <AddressAutocomplete
                value={field.value}
                onChange={value =>
                  setValue(
                    'street',
                    value.replace(
                      /[^a-zA-Z0-9\s.,\u00C0-\u024F\u1E00-\u1EFF\u0180-\u024F\u1D00-\u1D7F\u0400-\u04FF-]/g,
                      '',
                    ),
                    { shouldValidate: true },
                  )
                }
                onSelect={onAddressSelect}
                styles={style}
                placeholder=""
                hasError={!!errors.street}
              />
            )}
          />
          {errors.street?.message && (
            <ErrorMessage>{errors.street?.message}</ErrorMessage>
          )}
        </InputContainer>
        <InputContainer>
          <Label>{data?.apartmentLabel}</Label>
          <Input
            placeholder=""
            type="text"
            {...register('apartment')}
            hasError={!!errors.apartment?.message}
            onChange={e => handleInputChange(e, 'apartment')}
          />
          {errors.apartment?.message && (
            <ErrorMessage>{errors.apartment?.message}</ErrorMessage>
          )}
        </InputContainer>
        <InputContainer>
          <Label>{data?.townLabel}</Label>
          <Input
            placeholder=""
            type="text"
            {...register('town')}
            hasError={!!errors.town?.message}
            onChange={e => handleInputChange(e, 'town')}
          />
          {errors.town?.message && (
            <ErrorMessage>{errors.town?.message}</ErrorMessage>
          )}
        </InputContainer>
        <InputContainer>
          <Label>{data?.stateLabel}</Label>
          <Controller
            name="state"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                options={states}
                value={states.find(s => s.value === field.value) || null}
                onChange={(selectedOption: SingleValue<State>) => {
                  field.onChange(selectedOption?.value || '');
                }}
                styles={selectStyle}
                placeholder="Select"
                isDisabled={!watchCountry}
                components={{ DropdownIndicator }}
                hasError={!!errors.state}
              />
            )}
          />
          {errors.state?.message && (
            <ErrorMessage>{errors.state?.message}</ErrorMessage>
          )}
        </InputContainer>
        <InputContainer>
          <Label>{data?.countryLabel}</Label>
          <Controller
            name="country"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                options={countries}
                value={countries?.find(c => c.value === field.value)}
                onChange={(selectedOption: SingleValue<Country>) => {
                  field.onChange(selectedOption?.value);
                }}
                styles={selectStyle}
                placeholder="Select"
                components={{ DropdownIndicator }}
                hasError={!!errors.country}
              />
            )}
          />
          {showFreeShipping && (
            <FreeShippingContainer>
              <CircleCheck />
              <p>{data?.freeShippingLabel}</p>
            </FreeShippingContainer>
          )}
          {errors.country?.message && (
            <ErrorMessage>{errors.country?.message}</ErrorMessage>
          )}
        </InputContainer>
        <InputContainer>
          <Label>{data?.postalLabel}</Label>
          <Input
            placeholder=""
            type="text"
            {...register('zipCode')}
            hasError={!!errors.zipCode?.message}
            onChange={e => handleInputChange(e, 'zipCode')}
          />
          {errors.zipCode?.message && (
            <ErrorMessage>{errors.zipCode?.message}</ErrorMessage>
          )}
        </InputContainer>
        {taxedCountries.includes(watchCountry) && (
          <InputContainer>
            <Label>{data?.taxCodeLabel}</Label>
            <Input
              type="text"
              {...register('organization')}
              hasError={!!errors.organization?.message}
              onChange={e => handleOrganizationChange(e)}
            />
            {errors.organization?.message && (
              <ErrorMessage>{errors.organization?.message}</ErrorMessage>
            )}
          </InputContainer>
        )}

        {shippingErrors && shippingErrors.length > 0 && (
          <ErrorsContainer>
            {shippingErrors.map((error, index) => (
              <ErrorMessage key={`error-message=${index}`}>
                {error}
              </ErrorMessage>
            ))}
          </ErrorsContainer>
        )}
        <StyledPrimaryButton type="submit" loading={loading} disabled={loading}>
          Continue
        </StyledPrimaryButton>
        {/* <StyledPrimaryButton
          type="button"
          loading={loading}
          disabled={loading}
          onClick={() => console.log(getValues())}
        >
          Log
        </StyledPrimaryButton> */}
      </Form>
    </>
  );
};

export default ShippingForm;

const Form = styled.form`
  width: 100%;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  margin-bottom: 1rem;
`;

const Label = styled.p`
  color: #000;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.25rem;
`;

const Input = styled.input<{ hasError?: boolean }>`
  padding: 0rem 1rem;
  height: 3rem;
  border-radius: 0.625rem;
  border: 1px solid ${({ hasError }) => (hasError ? '#962828' : '#C7C9D9')};
  background: #fff;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  outline: none;
  transition: box-shadow 75ms ease-in-out;

  &:focus {
    border: 1px solid #4d4d4d;
  }

  &::placeholder {
    color: #8f90a6;
  }
`;

const ErrorMessage = styled.p`
  color: #962828;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1rem;
`;

const StyledPrimaryButton = styled(Button)`
  width: 100%;
  margin-top: 1.5rem;

  /* @media ${tablet} {
    margin-top: 1.5rem;
  } */
`;

const ErrorsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  margin: 1rem 0 0;
`;

const SectionTitle = styled.p`
  color: #000;
  font-feature-settings: 'liga' off, 'clig' off;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5rem;
  margin-top: 0.5rem;

  @media ${tablet} {
    margin-top: 0.5rem;
  }
`;

const FreeShippingContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;

  p {
    color: #00a676;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1rem;
  }
`;

const StyledCustomSelect = styled(CustomSelect)<{ disabled?: boolean }>`
  fill: ${({ disabled }) => (disabled ? '#C7C9D9' : '#8C8C8C')};
  stroke: ${({ disabled }) => (disabled ? '#C7C9D9' : '#8C8C8C')};
`;
