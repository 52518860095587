import { Text } from 'components';
import React, { FC } from 'react';
import styled from 'styled-components';
import { tablet } from 'styles/breakpoints';
import success from 'assets/lotties/success.json';
import Lottie from 'lottie-react';

interface SuccessSupportCaseProps {}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 3rem auto 3rem;
  max-width: 28.75rem;
  width: 100%;
  @media ${tablet} {
    margin: 2.25rem auto 3rem;
    padding: 0 1rem;
  }
`;

const Title = styled(Text)`
  color: #000;
  text-align: center;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  letter-spacing: -0.028rem;
  padding-bottom: 0.75rem;
  @media ${tablet} {
    font-size: 1.5rem;
    letter-spacing: -0.036rem;
  }
`;

const Label = styled(Text)`
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.375rem;
  padding-bottom: 2.375rem;
  text-align: center;
  span {
    font-weight: 700;
  }
`;

const SvgContainer = styled.div`
  width: 8.75rem;
  height: 8.75rem;
  margin-bottom: 0.75rem;
  svg {
    width: 8.75rem;
    height: 8.75rem;
  }
`;

const SuccessSupportCase: FC<SuccessSupportCaseProps> = () => (
  <Container>
    <SvgContainer>
      <Lottie animationData={success} loop={false} />
    </SvgContainer>

    <Title>Your order has been confirmed!</Title>

    <Label>
      We have sent a confirmation & will notify you via email once the order has
      been shipped.
    </Label>
  </Container>
);

export default SuccessSupportCase;
